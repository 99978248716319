@import url(https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;1,400&display=swap);
/* font used: Red Hat Display - Reg, Reg-i, Med, Bold */

html {
  scroll-behavior: smooth; 
  margin:0;
  padding:0;
  overflow-x:hidden;
} 


body {
  margin: 0;
  font-family: 'Red Hat Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin:0;
  padding:0;
  overflow-x:hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root{
  --white:#FFFFFF;
  --grey1:#ECECEC;
  --grey2:#DADADA;
  --grey3:#C4C4C4;
  --grey4:#909090;
  --black:#000000;
  --highlight-blue:#2196F3;
  --google-button-blue:#065FD4;
  --red:#FF0000;
  --dark-red:#DA0000;
}

h1{
  font-weight: bold;
}

h2{
  font-weight: 400;
}

button{
  font-weight: 700;
  background-color: #065FD4;
  background-color: var(--google-button-blue);
  color: #FFFFFF;
  color: var(--white);
  font-size: large;
  -webkit-filter: drop-shadow(3px 3px 2px #0000002f );
          filter: drop-shadow(3px 3px 2px #0000002f );
  border-radius: 5px;
}

button:hover{
  -webkit-filter: drop-shadow(5px 5px 2px #00000079 );
          filter: drop-shadow(5px 5px 2px #00000079 );
  -webkit-transform: translate(-2px,-2px);
          transform: translate(-2px,-2px);
  transition: all 0.2s;
}

#root{
  display: flex;
}


